.logoButton {
  position: absolute;
  top: 10px;
  left: 10px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.logoText {
  font-size: 24px;
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  color: var(--primary);
  margin: 0;
}

.logoBoxFrench {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logoTextFrench {
  font-size: 24px;
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  color: var(--primary);
  margin: 0;
}

.logoTextFrench:nth-child(1) {
  color: var(--primary);
}

.logoTextFrench:nth-child(2) {
  color: var(--secondary);
}

.logoTextFrench:nth-child(3) {
  color: var(--primary);
}
